import styled from '@emotion/styled';

import { PARTNERS } from 'constant/appConstant';
import { breakPoints } from 'constant/breakPoints';

function Partners(): JSX.Element {
  return (
    <Wrap>
      <h4 className="text-center title-section">OUR PARTNERS</h4>

      <PartnersContainerRow initialPosition="left" itemLen={5}>
        <div className="partner-row partner-row_animate">
          {PARTNERS.map((item, idx) => (
            <img key={idx} src={item.src} alt="SpiritLabs partner" />
          ))}
        </div>
        <div className="partner-row partner-row_animate">
          {PARTNERS.map((item, idx) => (
            <img key={idx} src={item.src} alt="SpiritLabs partner" />
          ))}
        </div>
      </PartnersContainerRow>

      <PartnersContainerRow initialPosition="right" itemLen={5}>
        <div className="partner-row partner-row_animate">
          {PARTNERS.map((item, idx) => (
            <img key={idx} src={item.src} alt="SpiritLabs partner" />
          ))}
        </div>
        <div className="partner-row partner-row_animate">
          {PARTNERS.map((item, idx) => (
            <img key={idx} src={item.src} alt="SpiritLabs partner" />
          ))}
        </div>
      </PartnersContainerRow>
    </Wrap>
  );
}

export default Partners;

const Wrap = styled.section`
  padding: 20px 0 150px;

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    padding-bottom: 70px;
  }
`;

const PartnersContainerRow = styled.div<{
  initialPosition: 'left' | 'right';
  itemLen: number;
}>`
  --slide-to: ${({ initialPosition }) =>
    initialPosition === 'left' ? '-100%' : '100%'};

  width: 100%;
  padding: 10px 0;
  margin: 70px 0;
  display: flex;
  flex-flow: ${({ initialPosition }) =>
      initialPosition === 'left' ? 'row' : 'row-reverse'}
    nowrap;
  overflow: hidden;

  .partner-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    position: relative;
    ${({ initialPosition }) => initialPosition}: 0;

    &_animate {
      animation-duration: ${({ itemLen }) => itemLen * 3}s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: slide;
    }

    img {
      height: auto;
      width: auto;
      max-height: 65px;
      margin: 0 109px;
    }

    @keyframes slide {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(var(--slide-to));
      }
    }
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    margin: 40px 0;
  }
`;
